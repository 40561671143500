.specialTestList .pic_content {
  margin-bottom: 20px;
  padding: 20px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.specialTestList .pic_content h2 {
  text-align: center;
  margin-bottom: 18px;
}
.specialTestList .pic_content div p {
  margin-bottom: 6px;
}
.specialTestList .pic_content div p.title {
  font-size: 16px;
  font-weight: 600;
}
.specialTestList .pic_content div .pic_box {
  width: 130px;
  height: 130px;
  margin-bottom: 15px;
}
.specialTestList .exportdialog .upload-workers {
  height: 30px;
}
.specialTestList .exportdialog .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
.specialTestList .exportdialog .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
