








































































































































































































































































































































































































































































































































































































































































































































































































































































































.specialTestList {
  .pic_content {
    margin-bottom: 20px;
    // background-color: #f2f7fd;
    padding: 20px;
    // border-radius: 10px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    h2 {
      text-align: center;
      margin-bottom: 18px;
    }
    div {
      p {
        margin-bottom: 6px;
      }
      p.title {
        font-size: 16px;
        font-weight: 600;
      }
      .pic_box {
        width: 130px;
        height: 130px;
        margin-bottom: 15px;
        // border: 1px solid #000;
      }
    }
  }
  // 导入学员
  .exportdialog {
    .upload-workers {
      height: 30px;
      .el-upload {
        height: 30px !important;
        width: 97px;
        border: none !important;
        .el-button {
          padding: 5px 0;
        }
      }
    }
  }
}
